import React, { useEffect, useState } from "react";
import { Container } from "semantic-ui-react";
import { ClientRoles } from "../../const/ClientRoles";
import KeycloakUserService from "../login/KeyCloakService";
import SidebarComp from "../menu/SidebarComp";
import DocumentList from "./DocumentList";
import LexxDashboardCard from "./LexxDashboardCard";
import {
  saveIsReviewer,
  setFormEdit,
  setStepOrdered,
  setStepEdit,
  passMediaURL,
  mediafiletorender
} from "./../../redux/actions/index";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

/* This is the component to display the Dashboard (main page after login) */
const LexxDashboard = (props) => {
  /* This component calls 2 components 
        1. Dashboard Card - for buttons
        2. Carousel - for session and task carousel
    */
  const [userRoles, setUserRoles] = useState(
    KeycloakUserService.getClientRoles()
  );
  useEffect(() => {
    const isReviewer = userRoles.filter(
      (role) => role === ClientRoles.reviewer
    );
    if (isReviewer.length > 0) {
      props.saveIsReviewer(true);
    } else {
      props.saveIsReviewer(false);
    }
    props.setFormEdit(false);
    props.setStepOrdered(false);
    props.setStepEdit(false);
    props.passMediaURL(null);
    props.mediafiletorender([]);
  }, []);

  return (
    <>
      {/* <SidebarComp /> */}
      <div className="content-container home-container">
        <Container className="dashboard-container">
          <h2>Dashboard</h2>
          <hr style={{ opacity: "50%" }} />
          <LexxDashboardCard />
          <DocumentList />
        </Container>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isReviewer: state.isReviewer,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    saveIsReviewer,
    setFormEdit,
    setStepOrdered,
    setStepEdit,
    passMediaURL,
    mediafiletorender
  })(LexxDashboard)
);
