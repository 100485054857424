import React, { useEffect, useRef, useState } from "react";
import { Accordion, Icon, Modal, Button, Popup } from "semantic-ui-react";
import { useDrag, useDrop } from "react-dnd";
import { ItemTypes } from "./ItemTypes.js";
import { connect, useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import photoImg from "../../assets/images/photos.png";
import videoImg from "../../assets/images/video.png";
import reorder from "../../assets/images/reorder.svg";
import {
  setEnableEditStep,
  editStepDetail,
  saveEditedStepIds,
  removeStep,
  setPreview,
  viewFeedback,
} from "./../../redux/actions";
import FroalaEditorViewOnly from "./FriolaEditor/FroalaEditorView";
import { deleteSteps } from "../apiCall";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min.js";

const Card = ({
  id,
  title,
  description,
  index,
  moveCard,
  media,
  disablePreview,
  mediaFullScreen,
}) => {
  const style = {};
  const location = useLocation();
  const [popupVisible, setPopupVisible] = useState(false);
  //location.pathname === "/Preview" to make the accordion open in preview page
  const [activeIndex, setActiveIndex] = useState(
    location.pathname === "/Preview" ? 1 : 0
  );
  const [isDefaultStep, setIsDefaultStep] = useState(false);
  const [showImages, setShowImages] = useState(false);
  const [showVideos, setShowVideos] = useState(false);
  const stepDetails = useSelector((state) => state.stepDetails);
  const [isPreview, setIsPreview] = useState(false);
  const [feedbackCount, setFeedbackCount] = useState(0);
  const dispatch = useDispatch();
  let history = useHistory();
  const editStep = useSelector((state) => state.editStep);
  const feedbackData = useSelector((state) => state.feedbackData);
  const isFromFeedback = useSelector((state) => state.isFromFeedback);
  const blankField = useSelector((state) => state.blankField);
  const [dropDown, setDropDown] = useState(false);
  const onClickSection = (e, props) => {
    id !== "001" && setDropDown(!dropDown);
    let sectionIndex = 1;
    let newActiveIndex = activeIndex === sectionIndex ? -1 : sectionIndex;
    id !== "001" && setActiveIndex(newActiveIndex);
  };

  useEffect(() => {
    if (title === "Step Name") {
      setIsDefaultStep(true);
    } else {
      setIsDefaultStep(false);
    }
  }, [title, isDefaultStep]);

  // Drag and Drop functionality
  const ref = useRef(null);

  useEffect(() => {
    if (id.length > 0 && media) {
      media.forEach((file) => {
        if (file.fileName) {
          const ext = file.fileName.split(".").pop();
          file.extention = ext;
        }
      });
    }
    if (history.location.pathname == "/Preview") {
      setIsPreview(true);
    }
    if (feedbackData.length > 0) {
      let count = 0;
      feedbackData.forEach((data) => {
        if (data.stepId[0] === id) {
          count++;
        }
      });
      setFeedbackCount(count);
    }
  }, [media, id]);

  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      if (!disablePreview) {
        // Time to actually perform the action
        moveCard(dragIndex, hoverIndex);
      }
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0.5 : 1;
  drag(drop(ref));

  // handle delete step
  const handleDelete = async (e) => {
    const selectedStep = stepDetails.filter((step) => step.stepId === id);
    // delete step
    await deleteStep(selectedStep[0].documentId);
    dispatch(removeStep(selectedStep[0].stepId));
    setPopupVisible(false);
  };

  // Delete Step API
  const deleteStep = async (documentId) => {
    await deleteSteps(documentId, id);
  };

  //handle Edit step
  const handleEditStep = () => {
    const selectedStep = stepDetails.filter((step) => step.stepId === id);
    if (isDefaultStep) {
      dispatch(editStepDetail(selectedStep));
    } else {
      const data = {
        title: selectedStep[0].stepTitle,
        description: selectedStep[0].stepDescription,
        stepId: selectedStep[0].stepId,
        order_id: selectedStep[0].order_id,
        media: selectedStep[0].media,
      };
      dispatch(editStepDetail(data));
      dispatch(saveEditedStepIds(selectedStep.id));
    }
    history.push("/CreateInstructionsSteps");
  };

  // Render the images aganist the selected step
  const renderDownloadedImages = () => {
    var displayImages = [];
    if (id.length > 0 && media?.length > 0) {
      for (let i = 0; i < media.length; i++) {
        if (
          media[i].extention === "png" ||
          media[i].extention === "PNG" ||
          media[i].extention === "jpeg" ||
          media[i].extention === "jpg" ||
          media[i].extention === "JPG" ||
          media[i].extention === "JPEG"
        ) {
          displayImages.push(
            <>
              <img
                className="image-display"
                src={media[i].url}
                alt=""
                onClick={() => {
                  mediaFullScreen(media[i]);
                }}
              />
            </>
          );
        }
      }
    }

    return displayImages;
  };

  // Render the videos aganist the selected step
  const renderDownloadedVideos = () => {
    var displayVideos = [];
    if (id.length > 0) {
      // Render files downloaded
      for (let i = 0; i < media.length; i++) {
        if (
          media[i].extention === "mp4" ||
          media[i].extention === "MOV" ||
          media[i].extention === "mov" ||
          media[i].extention === "MP4"
        ) {
          displayVideos.push(
            <video className="video-display" controls>
              <source
                className="video-display"
                src={media[i].url}
                alt=""
                type="video/mp4"
              />
            </video>
          );
        }
      }
    }
    return displayVideos;
  };

  const getStepTitle = (step) => {
    let stepTitle = "";
    if (step.length > 50) {
      stepTitle = step.substring(0, 90) + "...";
    } else {
      stepTitle = step;
    }
    return stepTitle;
  };

  const handleOpenFeedback = () => {
    dispatch(viewFeedback(true));
    handleEditStep();
  };

  return (
    <>
      <div
        onClick={onClickSection}
        ref={ref}
        style={{
          ...style,
          opacity,
          width: !isPreview ? "70vw" : "100%",
          cursor: "default",
        }}
        data-handler-id={handlerId}
      >
        <Accordion fluid styled>
          <Accordion.Title active={activeIndex === 1}>
            {!disablePreview && (
              <img
                src={reorder}
                alt=""
                className={
                  stepDetails.length > 1
                    ? "step-card action-icon-reorder"
                    : "step-card"
                }
              />
            )}
            <Popup
              style={{ fontSize: "10px", background: "rgb(242 247 251)" }}
              onClick={onClickSection}
              trigger={
                <div
                  onClick={onClickSection}
                  style={{
                    paddingRight: "15px",
                  }}
                >
                  {getStepTitle(title)}
                </div>
              }
              position="top center"
            >
              {title}
            </Popup>
            <div className="wrap-icons">
              {!isPreview && (
                <>
                  {feedbackData.length > 0 && isFromFeedback && (
                    <Popup
                      style={{
                        fontSize: "10px",
                        background: "rgb(242 247 251)",
                      }}
                      trigger={
                        <div
                          style={{ position: "relative" }}
                          onClick={() => handleOpenFeedback()}
                        >
                          <i index={1} className={"comment icon action-icon"}>
                            {feedbackCount > 0 && (
                              <span className="feedback-counter">
                                {feedbackCount}
                              </span>
                            )}
                          </i>
                        </div>
                      }
                      position="top center"
                    >
                      Feedback
                    </Popup>
                  )}
                  <Popup
                    style={{ fontSize: "10px", background: "rgb(242 247 251)" }}
                    trigger={
                      <div
                        className="expand"
                        onClick={() =>{ (!(!editStep || isDefaultStep || blankField)) && setPopupVisible(true)}}
                      >
                        <Icon
                          name="trash"
                          index={1}
                          className={
                            !editStep || isDefaultStep
                              ? "dropdown-icon"
                              : "dropdown-icon action-icon"
                          }
                          disabled={(!editStep || isDefaultStep || blankField)}
                        />
                      </div>
                    }
                    position="top center"
                  >
                    Delete Step
                  </Popup>
                  <Popup
                    style={{ fontSize: "10px", background: "rgb(242 247 251)" }}
                    trigger={
                      <div className="expand" onClick={() => { (!(!editStep || blankField)) && handleEditStep()}}>
                        <Icon
                          name="pencil alternate"
                          index={1}
                          className={
                            !editStep
                              ? "dropdown-icon"
                              : "dropdown-icon action-icon"
                          }
                          disabled={!editStep || blankField}
                        />
                      </div>
                    }
                    position="top center"
                  >
                    Edit Step
                  </Popup>
                </>
              )}

              <Popup
                style={{ fontSize: "10px", background: "rgb(242 247 251)" }}
                trigger={
                  <div className="expand" onClick={onClickSection}>
                    <Icon
                      name="chevron down"
                      index={1}
                      className={"dropdown-icon action-icon"}
                      style={{
                        transform:
                          (location.pathname === "/Preview"
                            ? !dropDown
                            : dropDown) && "rotate(180deg)",
                        fontSize: "1.2em",
                      }}
                      disabled={id === "001"}
                    />
                  </div>
                }
                position="top center"
              >
                {activeIndex === 0 || activeIndex === -1
                  ? "Expand Step"
                  : "Collapse Step"}
              </Popup>
            </div>
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 1}>
            <p style={{ fontSize: "14px" }}>
              <FroalaEditorViewOnly content={description} />
            </p>
            {media?.length > 0 && (
              <>
                <h4 style={{ fontSize: "14px" }}>Step attachments</h4>
                <ul style={{ listStyle: "none" }}>
                  {renderDownloadedImages().length > 0 && (
                    <li className="images-wrap">
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span style={{ display: "flex", alignItems: "center" }}>
                          <img src={photoImg} alt="" />
                          <span
                            className="record"
                            style={{ fontWeight: "600" }}
                          >
                            Images
                          </span>
                        </span>
                        <Popup
                          style={{
                            fontSize: "10px",
                            background: "rgb(242 247 251)",
                          }}
                          trigger={
                            <Icon
                              name="chevron down"
                              index={1}
                              className={"dropdown-icon action-icon"}
                              onClick={() => {
                                setShowImages(!showImages);
                              }}
                              style={{
                                transform: showImages && "rotate(180deg)",
                                fontSize: "1.2em",
                              }}
                            />
                          }
                          position="top center"
                        >
                          {!showImages ? "Expand Images" : "Collapse Images"}
                        </Popup>
                      </div>
                      {showImages && media?.length > 0 && (
                        <>
                          <li>{renderDownloadedImages()}</li>
                        </>
                      )}
                    </li>
                  )}
                  {renderDownloadedVideos().length > 0 && (
                    <li className="images-wrap">
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span style={{ display: "flex", alignItems: "center" }}>
                          <img src={videoImg} alt="" />
                          <span
                            className="record"
                            style={{ fontWeight: "600" }}
                          >
                            Videos
                          </span>
                        </span>
                        <Popup
                          style={{
                            fontSize: "10px",
                            background: "rgb(242 247 251)",
                          }}
                          trigger={
                            <Icon
                              name="chevron down"
                              index={1}
                              onClick={() => setShowVideos(!showVideos)}
                              className={"dropdown-icon action-icon"}
                              style={{
                                transform: showVideos && "rotate(180deg)",
                                fontSize: "1.2em",
                              }}
                            />
                          }
                          position="top center"
                        >
                          {!showVideos ? "Expand Videos" : "Collapse Videos"}
                        </Popup>
                      </div>
                      {showVideos && media?.length > 0 && (
                        <>
                          <li>{renderDownloadedVideos()}</li>
                        </>
                      )}
                    </li>
                  )}
                </ul>
              </>
            )}
          </Accordion.Content>
        </Accordion>
      </div>
      {popupVisible && (
        <Modal
          className="modal-upload-delete"
          open={popupVisible}
          onClose={() => setPopupVisible(false)}
        >
          <div className="modal-heading-delete">
            <div>
              <h3>Confirm Delete</h3>
              <p>Are you sure you want to delete the step?</p>
            </div>
            <button
              onClick={() => setPopupVisible(false)}
              style={{ marginRight: "1rem" }}
            >
              X
            </button>
          </div>
          <div className="modal-action-delete">
            <Button
              className="modal-button-option-del1"
              onClick={() => setPopupVisible(false)}
            >
              No
            </Button>
            <Button
              className="modal-button-option-del2"
              onClick={() => handleDelete()}
            >
              Yes
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    stepDetails: state.stepDetails,
    editStep: state.editStep,
    feedbackData: state.feedbackData,
    isFromFeedback: state.isFromFeedback,
    blankField: state.blankField,
  };
};

export default connect(mapStateToProps, {
  setEnableEditStep,
  editStepDetail,
  saveEditedStepIds,
  removeStep,
  setPreview,
  viewFeedback,
})(Card);