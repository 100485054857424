import React, { useState, useEffect } from "react";
import {
  Table,
  Label,
  Popup,
  Icon,
  Button,
  Grid,
  Dropdown,
} from "semantic-ui-react";
import { colorName } from "./colorname";
import {
  getFeedbackdocument,
  getDocumentById,
  getStepsByDocumentId,
} from "../apiCall";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import {
  setDocumentCollection,
  setProcedureDetails,
  setSelectedTemplate,
  updateDefaultStep,
  addStep,
  saveIsReviewer,
  setIsShowPreviewPage,
  setFeedback,
  setDocumentId,
  saveDocumentfeedback,
} from "../../redux/actions/index";
import KeycloakUserService from "../login/KeyCloakService";
import Feedbackpopup from "./FeedbackModal";

const Feedbacklist = (props) => {
  const [feedbacksByStatus, setfeedbacksByStatus] = useState([]);
  const [documentfeedback, setDocumentfeedback] = useState([]);
  const [popupVisible, setPopupVisible] = useState(false);

  const [statusFilter, setStatusFilter] = useState("all");
  const statusOptions = [
    {
      key: "all",
      text: "ALL",
      value: "all",
    },
    {
      key: "new",
      text: "NEW",
      value: "new",
    },
    {
      key: "resolved",
      text: "RESOLVED",
      value: "resolved",
    },
    {
      key: "declined",
      text: "DECLINED",
      value: "declined",
    },
  ];
  const getAllfeedbacksofDocument = () => {
    getFeedbackdocument(props.documentId)
      .then((response) => {
        const feedbackCollection = response.data.data.steps;
        setfeedbacksByStatus(feedbackCollection);
      })
      .catch((error) => {});
  };
  useEffect(() => {
    getAllfeedbacksofDocument();
    window.scrollTo(0, 0);
  }, [popupVisible]);

  //display feedbacks
  const displayTab = () => {
    return (
      <>
        {popupVisible && (
          <Feedbackpopup
            popupVisible={popupVisible}
            history={props.history}
            feedbackData={documentfeedback}
            handleClose={() => setPopupVisible(false)}
          />
        )}
        <div className="tableWrap-feedback">
          <Table celled unstackable>
            <Table.Header>
              <Table.Row style={{ position: "sticky", top: "0", zIndex: "1" }}>
                <Table.HeaderCell>Feedback Summary</Table.HeaderCell>
                <Table.HeaderCell className="table-header">
                  Created On
                </Table.HeaderCell>
                <Table.HeaderCell className="table-header">
                  Created By
                </Table.HeaderCell>
                <Table.HeaderCell className="table-header">
                  Feedback Type
                </Table.HeaderCell>
                <Table.HeaderCell className="table-header">
                  Status
                </Table.HeaderCell>
                <Table.HeaderCell className="table-header">
                  Section Name
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>{displayData()}</Table.Body>
          </Table>
        </div>
      </>
    );
  };

  const displayData = () => {
    return feedbacksByStatus
      .filter((data) =>
        statusFilter === "all"
          ? data
          : data.status.toLowerCase() === statusFilter
      )
      .map((info) => {
        return (
          <>
            <Table.Row key={info.commentId}>
              <Table.Cell className="table-content">
                <Popup
                  style={{ fontSize: "10px", background: "rgb(242 247 251)" }}
                  trigger={
                    <div>
                      {info.summaryOfFeedback.length > 20
                        ? info.summaryOfFeedback.substring(0, 20) + "..."
                        : info.summaryOfFeedback}
                    </div>
                  }
                  position="top center"
                >
                  {info.summaryOfFeedback}
                </Popup>
              </Table.Cell>
              <Table.Cell className="table-header">
                {moment(info.createdOn).format("MM/DD/YYYY")}
              </Table.Cell>
              <Table.Cell className="table-header">{info.createdBy}</Table.Cell>
              <Table.Cell className="table-header">
                {info.typeOfFeedback}
              </Table.Cell>
              <Table.Cell className={colorName(info.status)}>
                <Label horizontal>
                  {info.status.toUpperCase().replace("_", " ")}
                </Label>
              </Table.Cell>
              <Table.Cell className="align-center">
                <Popup
                  style={{ fontSize: "10px", background: "rgb(242 247 251)" }}
                  trigger={
                    <div>
                      {info.section[0].length > 20
                        ? info.section[0].substring(0, 20) + "..."
                        : info.section[0]}
                    </div>
                  }
                  position="top center"
                >
                  {info.section[0]}
                </Popup>
              </Table.Cell>
            </Table.Row>
          </>
        );
      });
  };

  const handleBackButton = () => {
    props.history.push("/");
  };
  const handleStatusChange = (data) => {
    setStatusFilter(data.value);
  };
  const getStoreData = async () => {
    await getDocumentById(props.documentId).then((response) => {
      props.setSelectedTemplate(response.data.data.document.documentType);
      const data = {
        procedureTitle: response.data.data.document.procedureTitle,
        procedureDescription: response.data.data.document.procedureDescription,
        status: response.data.data.document.status,
        category: response.data.data.document.category,
        documentId: response.data.data.document.documentId,
      };
      props.setProcedureDetails(data);
    });
    await getStepsByDocumentId(props.documentId).then((response) => {
      const data = response.data.data.steps;
      data.forEach((step, index) => {
        if (index === 0) {
          props.updateDefaultStep(step);
        } else {
          props.addStep(step);
        }
      });
      setTimeout(() => {
        props.history.push(`/CreateInstructionsForm`);
      }, 1000);
    });
  };

  const handleEditdocument = async (e) => {
    e.preventDefault();
    await getStoreData();
    props.saveDocumentfeedback(feedbacksByStatus);
  };

  return (
    <div className="feedback-table-page">
      <div className="feedback-wrapper">
        <div className="feedback-header">
          <h2>Feedback</h2>
        </div>
        <Popup
          style={{ fontSize: "10px", background: "rgb(242 247 251)" }}
          trigger={
            <Button
              className="open-document-btn"
              onClick={(e) => handleEditdocument(e)}
            >
              Open Document
            </Button>
          }
          position="top center"
        >
          Navigate to document edit page
        </Popup>
        <Grid.Column className="searchsolutionstatus feedback-filter">
          <Dropdown
            className="statusdropdown"
            selection
            options={statusOptions}
            defaultValue={statusOptions[0].value}
            onChange={(e, data) => handleStatusChange(data)}
          />
        </Grid.Column>
      </div>
      <hr style={{ opacity: "50%" }} />
      <div className="feedback-table-display">
        <div>{displayTab()}</div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    documentList: state.documentList,
    isReviewer: state.isReviewer,
    isFromFeedback: state.isFromFeedback,
    feedback: state.feedback,
    documentId: state.documentId,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    setDocumentCollection,
    setProcedureDetails,
    setSelectedTemplate,
    addStep,
    updateDefaultStep,
    saveIsReviewer,
    setIsShowPreviewPage,
    setFeedback,
    setDocumentId,
    saveDocumentfeedback,
  })(Feedbacklist)
);
