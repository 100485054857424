import update from "immutability-helper";
import React, { useCallback, useEffect, useState } from "react";
import Card from "./Card.jsx";
import {
  updateStep,
  saveEditedStepIds,
  setStepOrdered,
} from "./../../redux/actions/index";
import { useDispatch } from "react-redux";
import { Modal } from "semantic-ui-react";

export const Container = (steps, isPreview) => {
  const [cards, setCards] = useState(steps.steps);

  const [media, setMedia] = useState("");
  const [isCardMoved, setIsCardMoved] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setCards(steps.steps);
  }, [steps.steps]);

  useEffect(() => {
    sortCards();
  }, [cards]);

  const sortCards = () => {
    cards.sort(function (a, b) {
      return a.order_id - b.order_id;
    });
  };

  const moveCard = useCallback((dragIndex, hoverIndex) => {
    dispatch(setStepOrdered(true));
    setIsCardMoved(true);
    setCards((prevCards) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]],
        ],
      })
    );
  }, []);

  const mediaFullScreen = (media) => {
    setMedia(media);
  };

  // Render Each steps
  const renderCard = (card, index) => {
    if (card) {
      if (isCardMoved) {
        const cardDetails = {
          title: card.stepTitle,
          description: card.stepDescription,
          order_id: index,
          stepId: card.stepId,
          media: card.media,
        };
        dispatch(updateStep(cardDetails));
        if (card.stepId) {
          dispatch(saveEditedStepIds(card.stepId));
        }
      }

      return (
        <Card
          key={card.stepId}
          index={index}
          id={card.stepId}
          title={card.stepTitle}
          description={card.stepDescription}
          moveCard={moveCard}
          media={card.media}
          isPreview={steps.isPreview}
          disablePreview={steps.disableReorder}
          mediaFullScreen={mediaFullScreen}
        />
      );
    }
  };

  return (
    <>
      <div style={{ width: "100%" }}>
        {cards?.map((card, i) => renderCard(card, i))}
        {media && (
          <Modal className="modal-upload-image" open={media}>
            <button onClick={() => setMedia("")} className="image-close">
              X
            </button>
            <div className="modal-action-image">
              <img className="image-fullscreen" src={media.url} alt="" />
            </div>
          </Modal>
        )}
      </div>
    </>
  );
};

export default Container;
