import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Menu, Image, Icon, Popup, Loader, Modal } from "semantic-ui-react";
import prodLogo from "../../assets/images/lexx-logo.png";
import { connect } from "react-redux";
import {
  blankField,
  loginAction,
  setUnReadNotification,
  showPopupAction,
} from "../../redux/actions";
import axios from "axios";
import { sendFirebaseToken, authSignup, logData } from "../apiCall";
import firebase from "../../configs/firebase";
import { EventSourcePolyfill } from "event-source-polyfill";
import KeycloakUserService from "../login/KeyCloakService";
// import LexxGeolocation from "../LexxGeolocation/LexxGeolocation";
import Technician from "../../assets/images/technician.png";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

/* Component is used as a Header in webapp */
const Header = (props) => {
  const [unread, setUnread] = useState(0); //to show the number of unread messages
  const [showPopup, setShowPopup] = useState(false);
  const location = useLocation();

  /* This function converts the UTC date to readable date */
  const getDate = (dateUTC) => {
    return (
      dateUTC.getDate() +
      "/" +
      (dateUTC.getMonth() + 1) +
      "/" +
      dateUTC.getFullYear()
    );
  };

  useEffect(() => {
    setUnread(props.count);
  }, [props.count]);

  /* This function converts the UTC date to readable time */
  const getTime = (dateUTC) => {
    return dateUTC.getHours() + ":" + dateUTC.getMinutes();
  };

  const firstLoginCheck = () => {
    props.loginAction(
      KeycloakUserService.getEmail(),
      KeycloakUserService.getToken()
    );
    authSignup()
      .then((response) => {
        if (
          getDate(new Date(response.data.data.createTime)) ===
            getDate(new Date(response.data.data.updateTime)) &&
          getTime(new Date(response.data.data.createTime)) ===
            getTime(new Date(response.data.data.updateTime))
        ) {
          // props.history.push("/editProfile");
        }
      })
      .catch((err) => {
        console.log(err);
      });
    getNotificationUnreadCount();
  };
  /* get the notification unread Count for the user */
  const getNotificationUnreadCount = async () => {
    // FireBaseNotification(); // Firebase notification to be called
    // const source = await unreadNotification();
    // setUnread(source.data.data.unreadNum);
    // console.log(source);

    var EventSource = EventSourcePolyfill;
    const source = new EventSource(
      `${process.env.REACT_APP_BACKEND_URL}/notifications/unread/stream`,
      {
        headers: {
          Authorization: localStorage.getItem("app_token"), // or localStorage.getItem("myToken")
        },
      }
    );
    source.onmessage = function logEvents(event) {
      if (JSON.parse(event.data) > 0) {
        let unreadCount = JSON.parse(event.data);
        setUnread(unreadCount);
        props.setUnReadNotification(unreadCount);
      }
    };
  };

  /* This is the function to initalize the firebase Notification system */
  const FireBaseNotification = () => {
    if (firebase.messaging.isSupported()) {
      //check if firebase messaging is supported
      const messaging = firebase.messaging();
      Notification.requestPermission()
        .then(() => {
          // request for sending out Notifications
          return messaging.getToken();
        })
        .then((token) => {
          sendFirebaseToken({ token: token })
            .then()
            .catch((error) => {
              // send Firebase Token to the backend
              console.log(error);
            });
          messaging.onMessage((payload) => {
            // Default behavior is only showing notification when app is in background
            // This enables showing notification when app in FOREGROUND
            const notificationTitle = payload.notification.title;
            const notificationOptions = {
              body: payload.notification.body,
              icon: "http://static.wixstatic.com/media/d80370_7497cf15149845b59dccce3685746868~mv2.png/v1/fill/w_218,h_100,al_c,q_85,usm_0.66_1.00_0.01/Company%20Logo.webp",
            };
            if (!("Notification" in window)) {
              console.log("This browser does not support system notifications");
            } else if (Notification.permission === "granted") {
              // if notification granted
              var notification = new Notification(
                notificationTitle,
                notificationOptions
              );
              // This is where the onClick of notification in foreground is defined
              notification.onclick = function (event) {
                event.preventDefault(); // prevent the browser from focusing the Notification's tab
                window.open("/allNotifications", "_blank");
                notification.close();
              };
            }
          });
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  useEffect(firstLoginCheck, []);

  axios.defaults.headers.common["Authorization"] =
    localStorage.getItem("app_token");

  const logout = () => {
    props.history.push("/");
    const data = {
      params: {
        username: props.userInfo.username,
        sessionTime: props.timer / 1000,
      },
    }; //logout request sent to the backend
    logData(data);
    KeycloakUserService.doLogout();
  };

  const resetPage = () => {
    if (location.pathname === "/Preview") {
      props.history.push({
        pathname: "/",
      });
      localStorage.clear();
      window.location.reload(false);
    } else {
      if (props.editForm) {
        if (props.blankField) {
          setShowPopup(true);
        } else {
          props.showPopupAction(true);
        }
      } else {
        props.history.push({
          pathname: "/",
        });
        localStorage.clear();
        window.location.reload(false);
      }
    }
  };

  const getNotificationIcon = () => {
    return (
      <>
        <Icon
          name="inverted bell outline"
          onClick={() => props.history.push("/allNotifications")}
        />{" "}
        {unread > 0 ? (
          <span className="notification-number"> {unread} </span>
        ) : null}{" "}
      </>
    );
  };

  return (
    <>
      <Modal
        className="modal-upload-delete"
        open={showPopup}
        onClose={() => setShowPopup(false)}
      >
        <div className="modal-heading-delete" style={{ marginBottom: "20px" }}>
          <div>
            <h3>
              Please complete all mandatory fields to proceed, or select {""}
              {location.pathname === "/CreateInstructionsSteps"
                ? "Cancel"
                : "Back To Home"}
              {""} to exit.
            </h3>
          </div>

          <button
            style={{ marginRight: "0.4rem", cursor: "pointer" }}
            onClick={() => setShowPopup(false)}
          >
            X
          </button>
        </div>
      </Modal>
      {/* <LexxGeolocation /> */}
      <Menu borderless fixed="top" className="header" id="menu">
        <Menu.Item className="prod-logo">
          <Image
            src={prodLogo}
            className="header-logo-img"
            onClick={() => resetPage()}
          />
        </Menu.Item>
        <Menu.Item position="right" className="menu-icon-btn">
          <Popup
            style={{ fontSize: "10px", background: "rgb(242 247 251)" }}
            trigger={
              <div
                style={{ marginRight: "1.45rem" }}
                onClick={() => resetPage()}
              >
                <Icon
                  name=" inverted  home"
                  style={{ cursor: "pointer" }}
                  onClick={() => resetPage()}
                />
              </div>
            }
            position="top center"
          >
            Dashboard
          </Popup>

          <div
            style={{ marginRight: "1.45rem" }}
            // onClick={() => props.history.push("/allNotifications")}
          >
            <Icon name="inverted bell outline" disabled={true} />
            {/* {unread > 0 ? (
                  <span className="notification-number"> {unread} </span>
                ) : null} */}
          </div>
          {/* <Icon
                name="inverted bell outline"
                onClick={() => props.history.push("/allNotifications")}
              />
          {unread > 0 ? (
            <span className="notification-number"> {unread} </span>
          ) : null} */}
          <Popup
            style={{ fontSize: "10px", background: "rgb(242 247 251)" }}
            trigger={
              <Icon
                name=" inverted  power off"
                style={{ cursor: "pointer" }}
                onClick={() => logout()}
              />
            }
            position="top center"
          >
            Logout
          </Popup>
          <Popup
            style={{ fontSize: "10px", background: "rgb(242 247 251)" }}
            trigger={
              <div className="profile-wrapper">
                <Image
                  src={Technician}
                  circular
                  className="profile-image-holder"
                />
                <span style={{ fontSize: "10px", color: "#FFFF" }}>
                  {props.isReviewer ? "Reviewer" : "Author"}
                </span>
              </div>
            }
            position="top center"
          >
            My Profile
          </Popup>
          {/* <ContextMenu /> */}
        </Menu.Item>
      </Menu>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    sidebarVisible: state.sidebarVisible,
    isCompleted: state.isCompleted,
    userInfo: state.userInfo,
    isReviewer: state.isReviewer,
    count: state.count,
    blankField: state.blankField,
    showPopupRedux: state.showPopupRedux,
    editForm: state.editForm,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    loginAction,
    setUnReadNotification,
    showPopupAction,
  })(Header)
);
