import React, { useState } from 'react';
import { useEffect } from 'react';
import { Menu, Icon } from 'semantic-ui-react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';

/* This is the sidebar component for the app which various items */
const SidebarComp = (props) => {

  const [selectedClassName, setClassName] = useState('');

  useEffect(()=>{
    if(props.history.location.pathname === '/') {
      setClassName('dashboard');
    } else if (props.history.location.pathname === '/myteam') {
      setClassName('myteam');
    }else if (props.history.location.pathname === '/userProfile') {
      setClassName('userProfile');
    }else if (props.history.location.pathname === '/setting') {
      setClassName('setting');
    }
  },[props.history.location.pathname])

  /* Function redirects the user to the specific page based on the item clicked */
  const clickMenu = (section) => {// save in redux if the menu is open or not
    switch (section) {
      case 'team':
        setClassName('myteam');
        props.history.push('/myteam');
        break;
      case 'dashboard':
        setClassName('dashboard');
        props.history.push('/');
        break;
      case 'profile':
        setClassName('userProfile');
        props.history.push('/userProfile');
        break;
      default:
        setClassName('dashboard');
        props.history.push('/');
        break;
    }
  }

  return (
    <Menu
      as={Menu}
      animation='overlay'
      icon='labeled'
      vertical
      className='sidemenu'
    >
      <Menu.Item as='a' onClick={() => clickMenu('dashboard')} className={selectedClassName === 'dashboard' ? 'sidebar-selected' : ''} >
        <Icon name='home' />
        <span className={selectedClassName === 'dashboard' ? 'bold-text' : ''} >Dashboard</span>
      </Menu.Item>
      {/* <Menu.Item as='a' onClick={null} className='disable-color' >
        <Icon name='user outline' />
        My Profile
      </Menu.Item>
      <Menu.Item as='a' onClick={null} className='disable-color'>
        <Icon name='users' />
        My Team
      </Menu.Item>
      <Menu.Item as='a' onClick={null} className='disable-color'>
        <Icon name='setting' />
        Settings
      </Menu.Item> */}
      
    </Menu>
  );
};

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    location: state.geolocation
  }
}

export default withRouter(connect(mapStateToProps)(SidebarComp)) ;