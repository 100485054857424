import React, { useState } from "react";
import { Accordion, Icon, Modal, Button, Popup } from "semantic-ui-react";
import moveicon from "../../assets/images/Shape.png";
import photoImg from "../../assets/images/photos.png";
import videoImg from "../../assets/images/video.png";
import FroalaEditorViewOnly from './FriolaEditor/FroalaEditorView';

const ProcedureDiscriptionView = (props) => {
  const { mediaFullScreen } = props;
  const style = {};
  const [activeIndex, setActiveIndex] = useState(1);
  const [showImages, setShowImages] = useState(false);
  const [showVideos, setShowVideos] = useState(false);
  const [dropDown, setDropDown] = useState(false);
  const getprocedureTitle = (procedure) => {
    let procedureTitle = "";
    if (procedure.length > 50) {
      procedureTitle = procedure.substring(0, 90) + "...";
    } else {
      procedureTitle = procedure;
    }
    return procedureTitle;
  };
  const onClickSection = (e, props) => {
    setDropDown(!dropDown);
    let sectionIndex = props.index;
    let newActiveIndex = activeIndex === sectionIndex ? -1 : sectionIndex;
    setActiveIndex(newActiveIndex);
  };
  const renderImagesAndVideos = (renderImages = false) => {
    var rendreFiles = [];
    // Uploaded files
    if (props.fileDetails?.length > 0) {
      for (let i = 0; i < props.fileDetails.length; i++) {
        if (
          !renderImages &&
          (props.fileDetails[i].type === "video/mp4" ||
            props.fileDetails[i].type === "MOV" ||
            props.fileDetails[i].type === "mov" ||
            props.fileDetails[i].type === "video/quicktime" ||
            props.fileDetails[i].type === "video/*" ||
            props.fileDetails[i].type === "HEVC/H.264" ||
            props.fileDetails[i].type === "hevc/h.264")
        ) {
          rendreFiles.push(
            <div className="image-container">
              <video preload="metadata" className="image-display" controls>
                <source
                  className="image-display"
                  src={URL.createObjectURL(props.fileDetails[i])}
                  alt=""
                />
              </video>
            </div>
          );
        }
      }
    }
    // Downloaded files
    if (props.media?.length > 0) {
      props.media.forEach((media) => {
        if (
          !renderImages &&
          (media.extention === "mp4" ||
            media.extention === "MP4" ||
            media.extention === "MOV" ||
            media.extention === "mov")
        ) {
          rendreFiles.push(
            <div className="image-container">
              <video className="image-display" controls>
                <source
                  className="image-display"
                  src={media.url}
                  alt=""
                />
              </video>
            </div>
          );
        }
      });
    }
    return rendreFiles;
  };

  const validateMedia = () => {
    console.log(props?.fileDetails, props?.media);
    return true;
  };

  return (
    <>
      <div style={{ ...style, opacity: 1, width: "100%" }}>
        <Accordion fluid styled>
          <Accordion.Title active={activeIndex === 1}>
              Procedure description
            <div className="wrap-icons">
              <Popup
                style={{ fontSize: "10px", background: "rgb(242 247 251)" }}
                trigger={
                  <Icon
                    name="chevron down"
                    index={1}
                    onClick={onClickSection}
                    className="dropdown-icon action-icon"
                    style={{
                      transform: !dropDown && "rotate(180deg)",
                      fontSize: "1.2em",
                    }}
                  />
                }
                position="top center"
              >
                {activeIndex === 1
                  ? "Collapse Procedure Description"
                  : "Expand Procedure Description"}
              </Popup>
            </div>
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 1}>
            <p style={{ fontSize: "14px" }}>
              <FroalaEditorViewOnly content={props.description}/>
            </p>

            {validateMedia() && (
              <>
                {props.fileDetails?.length > 0 && (
                  <h4 style={{ fontSize: "14px" }}>Step attachments</h4>
                )}
                <ul style={{ listStyle: "none" }}>
                  {renderImagesAndVideos(false).length > 0 && (
                    <li style={{ marginLeft: "0" }}>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span style={{ display: "flex", alignItems: "center" }}>
                          <img src={videoImg} alt="" />
                          <span className="record">Videos</span>
                        </span>
                        <Popup
                          style={{
                            fontSize: "10px",
                            background: "rgb(242 247 251)",
                          }}
                          trigger={
                            <Icon
                              name="chevron down"
                              index={1}
                              onClick={() => setShowVideos(!showVideos)}
                              className={"dropdown-icon action-icon"}
                              style={{
                                transform: showVideos && "rotate(180deg)",
                                fontSize: "1.2em",
                              }}
                            />
                          }
                          position="top center"
                        >
                          {!showVideos ? "Expand Videos" : "Collapse Videos"}
                        </Popup>
                      </div>
                      {showVideos && <li>{renderImagesAndVideos(false)}</li>}
                    </li>
                  )}
                </ul>
              </>
            )}
          </Accordion.Content>
        </Accordion>
      </div>
    </>
  );
};

export default ProcedureDiscriptionView;
