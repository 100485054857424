import React, { useState, useRef, useEffect, useCallback } from "react";
import {
  Button,
  Form,
  Icon,
  Popup,
  Modal,
  Loader,
  Message,
} from "semantic-ui-react";
import { connect } from "react-redux";
import {
  editStepDetail,
  addStep,
  setSelectedTemplate,
  setEnableEditStep,
  setProcedureDetails,
  updateDefaultStep,
  updateStep,
  saveIsReviewer,
  resetDocumentMedia,
  setStepDetails,
  setStepMediaFiles,
  setStepEdit,
  passMediaURL,
  removeStep,
  setBlankFields,
  showPopupAction,
  setFormEdit,
} from "./../../redux/actions/index";
import {
  saveNewDocument,
  saveNewStep,
  saveEditedStep,
  updateDocument,
  uploadMedia,
  deleteSteps,
} from "../apiCall";
import FroalaEditor from "./FriolaEditor/FroalaEditor";
import ProgressBar from "@ramonak/react-progress-bar";
import ViewFeedback from "./ViewFeedback";
import useAutosave from "./useAutosave";
import KeycloakUserService from "../login/KeyCloakService";

const CreateInstructionsSteps = (props) => {
  //form is used for UI - to display data
  const [form, setForm] = useState(
    props.stepData?.description?.length > 0 || props.stepData?.title?.length > 0
      ? props.stepData
      : props.editStepData?.stepId?.length > 0
      ? props.editStepData
      : {
          title: "",
          description: "",
        }
  );

  //stepDataLocal is used for data management , get the stepId...
  const [stepDataLocal, setStepDataLocal] = useState(
    props.stepData?.description?.length > 0 || props.stepData?.title?.length > 0
      ? props.stepData
      : props.editStepData?.stepId?.length > 0
      ? props.editStepData
      : {
          title: "",
          description: "",
        }
  );
  const [prevFormVal, setPrevFormVal] = useState(
    props.stepData?.description?.length > 0 || props.stepData?.title?.length > 0
      ? props.stepData
      : props.editStepData?.stepId?.length > 0
      ? props.editStepData
      : {
          title: "",
          description: "",
        }
  );
  const [newStep, setNewStep] = useState(false);
  const [media, setMedia] = useState("");
  const [fileDetails, setFileDetails] = useState(
    props.stepMediaFiles?.fileDetails?.length > 0
      ? props.stepMediaFiles.fileDetails
      : []
  );
  const [videoFile, setVideoFile] = useState([]);

  const [saveInProgress, setSaveInProgress] = useState(false);
  const [fileUpload, setFileUpload] = useState(false);
  const [uploadedMediaURL, setUploadedMedia] = useState();
  const [stepDescription, setStepDescription] = useState("");
  const [feedbackVisible, setFeedbackVisible] = useState(false);
  const [showPendingAlert, setShowPendingAlert] = useState(false);
  const [changeoccured, setCangeOccured] = useState(false);
  const [showPendingAlertForNoOption, setShowPendingAlertForNoOption] =
    useState(false);
  const [showSaveAlert, setShowSaveAlert] = useState(false);
  const [deleteFileCollection, setDeleteFileCollection] = useState(
    props.stepMediaFiles?.deleteFileCollection
      ? props.stepMediaFiles.deleteFileCollection
      : []
  );
  const [showToast, setShowToast] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mediaFilesDownloaded, setMediaFilesDownloaded] = useState(
    props.stepMediaFiles?.mediaFilesDownloaded
      ? props.stepMediaFiles.mediaFilesDownloaded
      : props.editStepData && props.editStepData?.media
      ? props.editStepData.media
      : []
  );

  const [autosave, setAutosave] = useState(false);
  const [mediaToBeSaved, setMediaToBeSaved] = useState([]);
  const [feedbackCollection, setFeedbackCollection] = useState([]);
  const [enableCancelPopup, setEnableCancelPopup] = useState(false);
  const [stepContentChange, setStepContentChange] = useState(false);
  const [stepContentChange2, setStepContentChange2] = useState(false);
  const fileRef = useRef();
  const imageRef = useRef();
  let submitAlertClass = showSaveAlert ? "modal-alert animate" : "modal-alert";
  let submitAlertStyles =
    showSaveAlert || showPendingAlert
      ? {}
      : { opacity: 0, visibility: "hidden", pointerEvents: "none" };
  /* Call this function when there is any change to the form */
  const handleChange = (e) => {
    setCangeOccured(true);
    setForm({ ...form, [e.target.name]: e.target.value });
    setStepContentChange(true);
    setStepContentChange2(true);
    props.setFormEdit(true);
  };
  useEffect(() => {
    if (props.stepData?.title?.length > 0) {
      setStepContentChange2(true);
    }
    if (stepContentChange) {
      props.setStepDetails(form);
    }
    if (props.viewFeedback) {
      setFeedbackVisible(true);
    }
  }, [form]);

  useEffect(() => {
    if (
      fileDetails?.length > 0 ||
      deleteFileCollection?.length > 0 ||
      mediaFilesDownloaded?.length > 0
    ) {
      const data = {
        fileDetails: fileDetails,
        deleteFileCollection: deleteFileCollection,
        mediaFilesDownloaded: mediaFilesDownloaded,
      };
      props.setStepMediaFiles(data);
    }
  }, [fileDetails, mediaFilesDownloaded, deleteFileCollection]);

  useEffect(() => {
    if (stepDescription.length > 0) {
      if (form.description !== stepDescription) {
        setStepContentChange(true);
        setStepContentChange2(true);
      }
      setForm({ ...form, ["description"]: stepDescription });
      setStepDescription("");
    }
    if (form.title === "" && stepContentChange2) {
      props.setBlankFields(true);
    } else {
      props.setBlankFields(false);
    }
  }, [stepDescription, form]);
  useEffect(() => {
    showToast &&
      setTimeout(() => {
        setShowToast(false);
      }, 4000);
  }, [showToast]);

  const handlecancelStep = async (e) => {
    if (autosave || props?.stepData?.stepId?.length > 0) {
      if (form.title === "") {
        if (stepDataLocal?.stepId || props?.stepData?.stepId?.length > 0) {
          setEnableCancelPopup(false);
          props.showPopupAction(false);
          setShowPendingAlertForNoOption(true);
          await deleteSteps(
            props.procedureDetails.documentId,
            stepDataLocal?.stepId
              ? stepDataLocal?.stepId
              : props?.stepData?.stepId
          );
          props.removeStep(
            stepDataLocal?.stepId
              ? stepDataLocal?.stepId
              : props?.stepData?.stepId
          );
          props.history.goBack();
        } else {
          props.showPopupAction(false);
          e.preventDefault();
          props.history.goBack();
        }
      } else if (saveInProgress) {
        setShowToast(true);
        setEnableCancelPopup(false);
        props.showPopupAction(false);
      } else {
        if (newStep || props.stepData.newStep) {
          setEnableCancelPopup(false);
          props.showPopupAction(false);
          setShowPendingAlertForNoOption(true);
          await deleteSteps(
            props.procedureDetails.documentId,
            stepDataLocal?.stepId
              ? stepDataLocal?.stepId
              : props?.stepData?.stepId
          );
          props.removeStep(
            stepDataLocal?.stepId
              ? stepDataLocal?.stepId
              : props?.stepData?.stepId
          );
          props.history.goBack();
        } else {
          //else it is an already existing step
          setEnableCancelPopup(false);
          props.showPopupAction(false);
          setShowPendingAlertForNoOption(true);
          updateStepDetails(true);
        }
      }
    } else {
      props.showPopupAction(false);
      e.preventDefault();
      props.history.goBack();
    }
  };

  const renderUploadFile = (e) => {
    if (saveInProgress) {
      setShowToast(true);
    } else {
      e.preventDefault();
      e.stopPropagation();
      fileRef.current.click();
    }
  };

  const renderImageInsertFile = (e) => {
    e.preventDefault();
    e.stopPropagation();
    imageRef.current.click();
  };

  const getContent = useCallback(
    (data, type) => {
      setStepDescription(data);
    },
    [form]
  );

  useAutosave(() => {
    setAutosave(true);
    !enableCancelPopup &&
      !saveInProgress &&
      stepContentChange2 &&
      handleSave(false, false, true);
  }, 10 * 1000);

  const handleSave = async (
    stepSave = false,
    navigate = true,
    autosave = false
  ) => {
    if (!autosave) {
      setEnableCancelPopup(false);
      props.showPopupAction(false);
    }

    if (form.title === "") {
      !autosave && setStepContentChange2(true);
    } else if (!saveInProgress) {
      setSaveInProgress(true);
      props.setStepEdit(true);

      if (props.procedureDetails.documentId === undefined) {
        if (stepDataLocal.stepId !== undefined) {
          await updateStepDetails();
        } else {
          // Save Document - this part will run when  intially the doc is not saved and step need to be saved
          const documentId = await saveNewDocumentData();
          await updateDefaultStepDetails(documentId);
        }
      } else if (
        stepDataLocal.stepId === undefined ||
        props?.stepData?.stepId?.length === 0
      ) {
        // Update Document status
        if (props.isReviewer) {
          await updateDocumentstatus();
        }
        if (props.stepDetails[0].stepTitle === "Step Name") {
          await updateDefaultStepDetails(props.procedureDetails.documentId);
        } else {
          //save new step details

          await saveNewStepDeatails();
        }
      } else if (stepContentChange && stepDataLocal?.stepId) {
        //update step details
        await updateStepDetails();
      }
      if (navigate) {
        setShowPendingAlert(false);
        setShowSaveAlert(true);
      }
      if (!stepSave && !autosave) {
        setForm({
          title: "",
          description: "",
        });
        setStepDataLocal({
          title: "",
          description: "",
        });
        props.setStepDetails({
          title: "",
          description: "",
        });
        setFileDetails([]);
        setMediaFilesDownloaded([]);
        setStepContentChange(false);
        setStepContentChange2(false);
      }
      if (stepSave && navigate) {
        setTimeout(() => {
          props.history.goBack();
        }, 1000);
      } else {
        setTimeout(() => {
          setShowSaveAlert(false);
        }, 1000);
      }
      setSaveInProgress(false);
    } else {
      setShowToast(true);
    }
  };

  const updateDocumentstatus = async () => {
    const data = {
      procedureTitle: props.procedureDetails.procedureTitle,
      procedureDescription: props.procedureDetails.procedureDescription,
      documentType: props.template,
      status: "IN_PROGRESS",
      category: props.procedureDetails.category,
    };
    const formData = createFormDocumentData(data);
    await updateDocument(formData, props.procedureDetails.documentId)
      .then((response) => {})
      .catch((err) => {
        if (err.response.status === 403) {
          KeycloakUserService.doLogout();
        }
      });
  };

  // Save procedure data
  const saveNewDocumentData = async () => {
    let documentId = "";
    const data = {
      procedureTitle: props.procedureDetails.procedureTitle,
      procedureDescription: props.procedureDetails.procedureDescription,
      documentType: props.template,
      status: "DRAFT",
      category: props.procedureDetails.category,
    };
    const formData = createFormDocumentData(data);
    await saveNewDocument(formData).then((response) => {
      response.data.data.media.forEach((file) => {
        if (file.fileName) {
          const ext = file.fileName.split(".").pop();
          file.extention = ext;
        }
      });
      props.setProcedureDetails(response.data.data);
      props.resetDocumentMedia(true);
      documentId = response.data.data.documentId;
    });
    return documentId;
  };

  // Create formdata to save doc
  const createFormDocumentData = (doc) => {
    const formData = new FormData();
    let docDetail = {
      procedureTitle: doc.procedureTitle,
      procedureDescription: doc.procedureDescription,
      documentType: doc.documentType,
      status: doc.status,
      category: doc.category,
    };
    formData.append("data", JSON.stringify(docDetail));
    if (props.docMedia.fileDetails && props.docMedia.fileDetails.length > 0) {
      for (let i = 0; i < props.docMedia.fileDetails.length; i++) {
        formData.append("media", props.docMedia.fileDetails[i]);
      }
    }
    return formData;
  };
  const getNewMedia = () => {
    const newMediaFileName = mediaFilesDownloaded
      .map((media) => {
        const isUniqueFileName = prevFormVal.media.every((prevMedia) => {
          if (prevMedia.fileName !== media.fileName) {
            return media.fileName;
          }
        });

        if (isUniqueFileName) {
          return media.fileName;
        }

        return null; // or any default value if needed
      })
      .filter(Boolean); // Filter out null values

    return newMediaFileName;
  };
  // Update step details
  const updateStepDetails = async (isPrevVal = false) => {
    var data = {
      title: isPrevVal ? prevFormVal.title : form.title,
      description: isPrevVal ? prevFormVal.description : form.description,
      order_id: isPrevVal ? prevFormVal.order_id : stepDataLocal.order_id,
      stepId: isPrevVal ? prevFormVal.stepId : stepDataLocal.stepId,
      uploadedMedia: isPrevVal ? prevFormVal.media : mediaToBeSaved,
      mediaToBeDeleted: isPrevVal ? getNewMedia() : deleteFileCollection,
    };

    // Update the existing step with edited values
    const formData = createFormData(data, true);
    await saveEditedStep(
      props.procedureDetails.documentId
        ? props.procedureDetails.documentId
        : localStorage.getItem("documentId"),
      formData,
      stepDataLocal.stepId
    )
      .then((response) => {
        data.media = response.data.data.media;
        props.setStepDetails(data);
        if (response.data.data?.media?.length > 0) {
          setMediaFilesDownloaded(getMediaFiles(response.data.data.media));
        }
        setVideoFile(getMediaFiles(response.data.data.media));
        mediaToBeSaved.length > 0 && setMediaToBeSaved([]);
        setStepContentChange(false);
        isPrevVal && props.history.goBack();
      })
      .catch((err) => {
        if (err.response.status === 403) {
          KeycloakUserService.doLogout();
        }
      });
    props.updateStep(data);
  };

  //Save new step details
  const updateDefaultStepDetails = async (documentId) => {
    setNewStep(true);

    if (form.title.length > 0) {
      var data = {
        title: form.title,
        description: form.description,
        order_id: 0,
        tableData: [],
        stepId: "",
        uploadedMedia: mediaToBeSaved,
      };
      const formData = createFormData(data);
      await saveNewStep(formData, documentId).then((response) => {
        data.stepId = response.data.data.steps.stepId;
        const result = response.data.data.steps;
        data.newStep = true;
        setMediaFilesDownloaded(getMediaFiles(response.data.data.steps.media));
        //setForm(data);
        props.setStepDetails(data);
        setStepDataLocal(data);
        mediaToBeSaved.length > 0 && setMediaToBeSaved([]);

        props.updateDefaultStep(result);
        setStepContentChange(false);
      });
    }
  };

  const getMediaFiles = (media) => {
    media.forEach((file) => {
      file.extention = file.fileName.split(".").pop();
    });
    setFileDetails([]);
    return media;
  };

  const saveNewStepDeatails = async () => {
    setNewStep(true);
    if (form.title.length > 0) {
      var data = {
        title: form.title,
        description: form.description,
        order_id: props.stepDetails.length,
        stepId: "",
        uploadedMedia: mediaToBeSaved,
      };
      const formData = createFormData(data);
      await saveNewStep(formData, props.procedureDetails.documentId).then(
        (response) => {
          const result = response.data.data.steps;
          props.addStep(result);
          data.stepId = response.data.data.steps.stepId;
          data.newStep = true;
          props.setStepDetails(data);
          setStepDataLocal(data);
          setMediaFilesDownloaded(
            getMediaFiles(response.data.data.steps.media)
          );
          setStepContentChange(false);
          mediaToBeSaved.length > 0 && setMediaToBeSaved([]);
        }
      );
    }
  };

  // Create formdata to save step
  const createFormData = (step, isEditstep = false) => {
    const formData = new FormData();
    const uploadedMedia = step.uploadedMedia;
    let stepDetail;
    if (isEditstep) {
      stepDetail = {
        stepTitle: step.title,
        description: step.description,
        order_id: step.order_id,
        _id: step.stepId,
        mediaToBeDeleted: step.mediaToBeDeleted,
      };
    } else {
      stepDetail = {
        stepTitle: step.title,
        description: step.description,
        order_id: step.order_id,
        stepId: "",
        tableData: [],
      };
    }
    formData.append("data", JSON.stringify(stepDetail));
    if (uploadedMedia && uploadedMedia.length > 0) {
      for (let i = 0; i < uploadedMedia.length; i++) {
        formData.append("media", uploadedMedia[i]);
      }
    }
    return formData;
  };

  // Set Uploaded files
  const handleInputChange = async (e, type) => {
    setStepContentChange(true);
    setStepContentChange2(true);
    e.preventDefault();
    const fileData = e.target.files;
    if (type === "video") {
      for (let i = 0; i < fileData.length; i++) {
        if (validateFile(fileData[i])) {
          if (!fileData[i].name) {
            const binary = atob(fileData[i].split(",")[1]);
            const array = [];
            var file;
            for (let i = 0; i < binary.length; i += 1) {
              array.push(binary.charCodeAt(i));
            }
            file = new Blob([new Uint8Array(array)], {
              type: "image/png",
            });
            file.name = `${new Date()}.png`;
            file.lastModifiedDate = new Date();
            const myFile = new File([file], file.name, {
              type: file.type,
            });
            setFileDetails((fileDetails) => [...fileDetails, myFile]);
            setMediaToBeSaved((fileDetails) => [...fileDetails, myFile]);
          }
          setFileDetails((fileDetails) => [...fileDetails, fileData[i]]);
          setMediaToBeSaved((fileDetails) => [...fileDetails, fileData[i]]);
        }
      }
    } else {
      const formFile = await getformData(fileData);
      setShowPendingAlert(true);
      await uploadMedia(formFile).then((res) => {
        setUploadedMedia(res.data.data.url);
        setShowPendingAlert(false);
        setFileUpload(true);
        props.passMediaURL(res.data.data.url);
        setShowSaveAlert(true);
        setTimeout(() => {
          setShowSaveAlert(false);
          setFileUpload(false);
        }, 1000);
      });
    }
  };

  const getformData = async (file) => {
    const formData = new FormData();
    formData.append("media", file[0]);
    return formData;
  };
  const getUrl = () => {
    navigator.clipboard.writeText(uploadedMediaURL);
    setUploadedMedia();
    setFileDetails([]);
  };

  const validateFile = (fileData) => {
    const fileType = fileData.name.split(".").pop().toLowerCase();
    if (fileData.size > 100 * 1024 * 1024) {
      const fileSize = (fileData.size / 1024 / 1024).toFixed(2);
      alert(
        "The attached file (" +
          fileSize +
          ") exceeds the maximum size limit of 100 MB. Please reduce the file size and try again."
      );
      setLoading(false);
      return false;
    } else if (!(fileType === "mov" || fileType === "mp4")) {
      alert(
        "We do not accept files of this media type. Only file with mp4 and mov are allowed."
      );
      setLoading(false);
      return false;
    } else {
      const isFileExist = fileDetails?.some(
        (file) => file.name === fileData.name
      );
      const isFileAlreadySaved = mediaFilesDownloaded?.some(
        (file) => file.fileName.split("/").pop() === fileData.name
      );
      if (isFileExist || isFileAlreadySaved) {
        alert(
          "The file you are trying to upload has the same name as an existing file. Please rename the file or choose a different one."
        );
        setLoading(false);
        return false;
      }
    }
    return true;
  };

  // Remove files
  const removeFile = (fileName, collectionName) => {
    setStepContentChange(true);
    setStepContentChange2(true);
    const fileUpdatedCollection = [];
    switch (collectionName) {
      case "fileDetails":
        if (fileDetails.length > 0) {
          for (let i = 0; i < fileDetails.length; i++) {
            if (fileDetails[i].name !== fileName) {
              fileUpdatedCollection.push(fileDetails[i]);
            }
          }
          setFileDetails(fileUpdatedCollection);
        }
        break;
      case "media":
        if (mediaFilesDownloaded) {
          mediaFilesDownloaded.forEach((media) => {
            if (media.fileName !== fileName) {
              fileUpdatedCollection.push(media);
            } else {
              setDeleteFileCollection((deleteFileCollection) => [
                ...deleteFileCollection,
                media.fileName,
              ]);
            }
          });
          setMediaFilesDownloaded(fileUpdatedCollection);
        }
        break;
      default:
        break;
    }
  };
  const mediaFullScreen = (media) => {
    setMedia(media);
  };

  const getTransitionTime = () => {
    if (fileDetails.length > 0) {
      for (let i = 0; i < fileDetails.length; i++) {
        if (
          fileDetails[i].type === "video/mp4" ||
          fileDetails[i].type === "MOV" ||
          fileDetails[i].type === "mov" ||
          fileDetails[i].type === "video/quicktime" ||
          fileDetails[i].type === "video/*"
        ) {
          return "40s";
        }
        return "3s";
      }
    } else if (fileDetails.length > 5) {
      return "10s";
    }
    return "2s";
  };

  const renderImagesAndVideos = () => {
    var rendreFiles = [];
    // Downloaded files
    if (mediaFilesDownloaded) {
      mediaFilesDownloaded.forEach((media) => {
        if (
          media.extention === "png" ||
          media.extention === "PNG" ||
          media.extention === "jpeg" ||
          media.extention === "JPEG" ||
          media.extention === "jpg" ||
          media.extention === "JPG"
        ) {
          rendreFiles.push(
            <div className="image-container">
              <Popup
                style={{ fontSize: "10px", background: "rgb(242 247 251)" }}
                trigger={
                  <img
                    className="photo"
                    src={media.url}
                    typeof="image/png, image/jpeg, image/jpg"
                    alt=""
                    onClick={() => {
                      mediaFullScreen(media);
                    }}
                  />
                }
                position="left center"
              >
                {media.fileName.split("/")[2]}
              </Popup>

              <button
                className="close-button-position"
                onClick={() => removeFile(media.fileName, "media")}
              >
                X
              </button>
            </div>
          );
        }
        if (
          media.extention === "mp4" ||
          media.extention === "MP4" ||
          media.extention === "MOV" ||
          media.extention === "mov"
        ) {
          rendreFiles.push(
            <div className="image-container">
              <Popup
                style={{ fontSize: "10px", background: "rgb(242 247 251)" }}
                trigger={
                  <video
                    className="photo"
                    onClick={() => {
                      mediaFullScreen(media);
                    }}
                  >
                    <source className="photo" src={media.url} alt="" />
                  </video>
                }
                position="left center"
              >
                {media.fileName.split("/")[2]}
              </Popup>

              <button
                className="close-button-position"
                onClick={() => removeFile(media.fileName, "media")}
              >
                X
              </button>
            </div>
          );
        }
      });
    }
    // Uploaded files
    if (fileDetails?.length > 0) {
      for (let i = 0; i < fileDetails.length; i++) {
        if (
          fileDetails[i].type === "image/png" ||
          fileDetails[i].type === "image/jpeg" ||
          fileDetails[i].type === "image/jpg" ||
          fileDetails[i].type === "image/JPG" ||
          fileDetails[i].type === "image/JPEG" ||
          fileDetails[i].type === "image/PNG"
        ) {
          rendreFiles.push(
            <div className="image-container">
              <Popup
                style={{ fontSize: "10px", background: "rgb(242 247 251)" }}
                trigger={
                  <img
                    src={URL.createObjectURL(fileDetails[i])}
                    className="photo"
                    typeof="image/png, image/jpeg, image/jpg, image/*, JPEG/H.264"
                    alt=""
                    onClick={() => {
                      mediaFullScreen(fileDetails[i]);
                    }}
                  />
                }
                position="left center"
              >
                {fileDetails[i].name}
              </Popup>

              <button
                className="close-button-position"
                onClick={() => removeFile(fileDetails[i].name, "fileDetails")}
              >
                X
              </button>
            </div>
          );
        } else if (
          fileDetails[i].type === "video/mp4" ||
          fileDetails[i].type === "MOV" ||
          fileDetails[i].type === "mov" ||
          fileDetails[i].type === "video/quicktime" ||
          fileDetails[i].type === "video/*" ||
          fileDetails[i].type === "HEVC/H.264" ||
          fileDetails[i].type === "hevc/h.264"
        ) {
          rendreFiles.push(
            <div className="image-container">
              <Popup
                style={{ fontSize: "10px", background: "rgb(242 247 251)" }}
                trigger={
                  <video
                    preload="metadata"
                    className="photo"
                    onClick={() => {
                      mediaFullScreen(fileDetails[i]);
                    }}
                  >
                    <source
                      className="photo"
                      src={URL.createObjectURL(fileDetails[i])}
                      alt=""
                    />
                  </video>
                }
                position="left center"
              >
                {fileDetails[i].name}
              </Popup>

              <button
                className="close-button-position"
                onClick={() => removeFile(fileDetails[i].name, "fileDetails")}
              >
                X
              </button>
            </div>
          );
        }
      }
    }
    return rendreFiles;
  };

  const renderFeedback = (data) => {
    return <ViewFeedback stepId={stepDataLocal.stepId} feedback={data} />;
  };
  useEffect(() => {
    mediaToBeSaved.length > 0 && handleSave(false, false, true);
    mediaToBeSaved.length === 0 && setLoading(false);
  }, [mediaToBeSaved]);
  const handleVideoUpload = (e) => {
    setLoading(true);
    handleInputChange(e, "video");
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    handleFeedbackData();
  }, [props.feedbackData]);

  const viewFeedbackData = () => {
    handleFeedbackData();
    setFeedbackVisible(true);
  };

  const handleFeedbackData = () => {
    var feedbackArray = [];

    props.feedbackData.forEach((feedback) => {
      let stepID =
        stepDataLocal.stepId !== undefined
          ? typeof stepDataLocal.stepId === "string"
            ? stepDataLocal.stepId
            : stepDataLocal.stepId[0]
          : "";
      if (feedback.stepId[0] === stepID) {
        for (let i = 0; i < feedback.media.length; i++) {
          const ext = feedback.media[i].fileName.split(".")[1];
          feedback.media[i].ext = ext;
        }
        feedbackArray.push(feedback);
      }
    });

    setFeedbackCollection(feedbackArray);
  };
  const closehandler = (e) => {
    if (saveInProgress) {
      setShowToast(true);
    } else {
      stepContentChange2 ? setEnableCancelPopup(true) : handlecancelStep(e);
    }
  };
  return (
    <div className="session-summary">
      <Modal open={loading}>
        <Loader active>Video is processing...</Loader>
      </Modal>
      {media.type === "image/png" ||
      media.type === "image/jpeg" ||
      media.type === "image/jpg" ||
      media.type === "image/JPG" ||
      media.type === "image/JPEG" ||
      media.type === "image/PNG" ||
      media.extention === "png" ||
      media.extention === "PNG" ||
      media.extention === "jpeg" ||
      media.extention === "JPEG" ||
      media.extention === "jpg" ||
      media.extention === "JPG" ? (
        <Modal className="modal-upload-image" open={media}>
          <button onClick={() => setMedia("")} className="image-close">
            X
          </button>
          <div className="modal-action-image">
            <img
              className="image-fullscreen"
              src={
                media.type !== undefined
                  ? URL.createObjectURL(media)
                  : media.url
              }
              alt="image"
            />
          </div>
        </Modal>
      ) : (
        <Modal className="modal-upload-image" open={media}>
          <button onClick={() => setMedia("")} className="image-close">
            X
          </button>
          <div className="modal-action-image">
            <video preload="metadata" className="image-fullscreen" controls>
              <source
                className="photo"
                src={
                  media.type !== undefined
                    ? URL.createObjectURL(media)
                    : media.url
                }
                alt=""
              />
            </video>
          </div>
        </Modal>
      )}
      <Form>
        {props.isFromFeedback && (
          <div className="feedback-btn-wrap">
            <Popup
              style={{ fontSize: "10px", background: "rgb(242 247 251)" }}
              trigger={
                <Button className="savebtn" onClick={() => viewFeedbackData()}>
                  View Feedbacks
                </Button>
              }
              position="top center"
            >
              View Feedbacks
            </Popup>
          </div>
        )}
        <p
          style={{
            fontSize: "10px",
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "-25px",
            marginBottom: "-25px",
          }}
        >
          Autosaving in every 10 seconds.
        </p>
        <h3 style={{ backgroundColor: "#F5F5F5", marginTop: "1.25rem" }}>
          Detailed Step
        </h3>
        <div className={feedbackVisible ? "step-feedback-wrap" : ""}>
          <div
            id="step-wrapper"
            className={feedbackVisible ? "step-wrapper" : ""}
          >
            <Form.Field
              required
              className={feedbackVisible ? "" : "input-wrap"}
            >
              <p className="form-label">Step title *</p>
              <input
                name="title"
                placeholder="Step title in here"
                className="instruction-input"
                value={form.title}
                onChange={handleChange}
                maxLength="250"
              />

              {form.title === "" && stepContentChange2 && (
                <span style={{ color: "red" }}>
                  Step Title is a mandatory field.
                </span>
              )}
            </Form.Field>
            <Form.Field
              required
              className={feedbackVisible ? "" : "input-wrap"}
            >
              <p className="form-label">What would you do for this step?</p>
              <FroalaEditor
                content={form.description}
                getContent={getContent}
              />
            </Form.Field>
            <div className="myContainer">
              <div className="take">
                <Button className="savebtn" onClick={renderUploadFile}>
                  Attach Video
                </Button>
                <Popup
                  style={{ fontSize: "10px", background: "rgb(242 247 251)" }}
                  trigger={
                    <Icon
                      name="exclamation circle"
                      className="exclamation-icon"
                    />
                  }
                  position="right center"
                >
                  Only file with mp4 and mov are allowed
                </Popup>
                <input
                  type="file"
                  id="video"
                  className="takefile"
                  name="media"
                  multiple
                  accept="video/mp4, JPEG/H.264, MOV, mov"
                  ref={fileRef}
                  onChange={(e) => {
                    setCangeOccured(true);
                    handleVideoUpload(e);
                  }}
                />
              </div>
              <div>
                {fileDetails && (
                  <div className="image-wrap">{renderImagesAndVideos()}</div>
                )}
              </div>
            </div>
          </div>
          {feedbackVisible && (
            <div className="view-feedback" style={{ height: "660px" }}>
              <Icon name="close" onClick={() => setFeedbackVisible(false)} />
              <div style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                <div className="feedback-header">Feedback</div>
              </div>
              {feedbackCollection.map((data) => renderFeedback(data))}
            </div>
          )}
        </div>
      </Form>
      <div className="buttons">
        {!props.isFromFeedback && (
          <Popup
            style={{
              fontSize: "10px",
              background: "rgb(242 247 251)",
              width: "160px",
            }}
            trigger={
              <Button
                className="savebtn"
                onClick={() => handleSave(false)}
                disabled={
                  form.title.length === 0 || showPendingAlert || showSaveAlert
                }
              >
                Save and New
              </Button>
            }
            position="top center"
          >
            Save step and start new step
          </Popup>
        )}
        <Popup
          style={{
            fontSize: "10px",
            background: "rgb(242 247 251)",
            width: "120px",
          }}
          trigger={
            <Button
              className="savebtn"
              onClick={(e) => handleSave(e, true)}
              disabled={
                form.title.length === 0 || showPendingAlert || showSaveAlert
              }
            >
              Save and Return
            </Button>
          }
          position="top center"
        >
          Save step and return
        </Popup>{" "}
        <Popup
          style={{
            fontSize: "10px",
            background: "rgb(242 247 251)",
            width: "80px",
          }}
          trigger={
            <Button
              className="cancelbtn"
              onClick={(e) => closehandler(e)}
              disabled={showPendingAlert || showSaveAlert}
            >
              Cancel
            </Button>
          }
          position="top center"
        >
          Cancel step
        </Popup>
      </div>
      {showPendingAlert && (
        <div
          className={submitAlertClass}
          style={submitAlertStyles}
          variant={"warning"}
        >
          <div className="modal-alert-icon">
            <img
              className="PopUpIcon"
              src={require("../../assets/images/pending-shape.png")}
              alt=""
            />
          </div>
          <div className="model-alert-msg">
            <h4 className="Heading">
              {fileUpload
                ? "File upload in progress"
                : "Step saving in progress"}
            </h4>
            <p className="Para">
              {fileUpload
                ? "Please wait until file upload completed"
                : "Please wait while saving is completed"}
            </p>
            <ProgressBar
              completed={90}
              bgColor="#08bd5c"
              labelColor="#1e1818"
              transitionDuration={getTransitionTime()}
              transitionTimingFunction="ease-in"
              animateOnRender
              customLabel=" "
              height=""
            />
          </div>
        </div>
      )}

      <div>
        <Modal open={showPendingAlertForNoOption}>
          <Loader active>Loading...</Loader>
        </Modal>
      </div>

      {showToast && (
        <div className="toast  ">
          <Message color="red" size="large">
            <Message.Header>Message</Message.Header>
            <p>
              Autosave in progress! Please hold for a moment and do it again.
            </p>
          </Message>
        </div>
      )}
      {showSaveAlert && (
        <div
          className={submitAlertClass}
          style={submitAlertStyles}
          variant={"success"}
        >
          <div className="modal-alert-icon">
            <img
              className="PopUpIcon"
              src={require("../../assets/images/Shape 1.png")}
              alt=""
            />
          </div>
          <div className="model-alert-msg">
            <h4 className="Heading">
              {fileUpload ? "File Uploaded" : "Step Saved"}
            </h4>
            <p className="Para">
              {fileUpload
                ? "Now you can copy the media url"
                : "You can edit it again later"}
            </p>
            <ProgressBar
              completed={100}
              bgColor="#08bd5c"
              labelColor="#1e1818"
              customLabel=" "
              height=""
            />
          </div>
        </div>
      )}
      {(enableCancelPopup || props.showPopupRedux) && (
        <Modal
          className="modal-upload-delete"
          open={enableCancelPopup || props.showPopupRedux}
          onClose={() => {
            setEnableCancelPopup(false);
            props.showPopupAction(false);
          }}
        >
          <div className="modal-heading-delete">
            <div>
              <h3>Confirm Cancel</h3>
              <p>Do you want to save step?</p>
            </div>

            <button
              style={{ marginRight: "1rem", cursor: "pointer" }}
              onClick={() => {
                setEnableCancelPopup(false);
                props.showPopupAction(false);
              }}
            >
              X
            </button>
          </div>
          <div className="modal-action-delete">
            <Button
              className="modal-button-option-del2"
              onClick={(e) => handleSave(e, true)}
            >
              Yes
            </Button>
            <Button
              className="modal-button-option-del1"
              onClick={(e) => handlecancelStep(e)}
            >
              No
            </Button>
          </div>
        </Modal>
      )}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    stepDetails: state.stepDetails,
    template: state.template,
    editStep: state.editStep,
    editStepData: state.editStepData,
    procedureDetails: state.procedureDetails,
    isReviewer: state.isReviewer,
    docMedia: state.docMedia,
    stepData: state.stepData,
    stepMediaFiles: state.stepMediaFiles,
    feedbackData: state.feedbackData,
    viewFeedback: state.viewFeedback,
    isFromFeedback: state.isFromFeedback,
    blankField: state.blankField,
    showPopupRedux: state.showPopupRedux,
  };
};

export default connect(mapStateToProps, {
  addStep,
  removeStep,
  setSelectedTemplate,
  setEnableEditStep,
  editStepDetail,
  setProcedureDetails,
  updateDefaultStep,
  updateStep,
  saveIsReviewer,
  resetDocumentMedia,
  setStepDetails,
  setStepMediaFiles,
  setStepEdit,
  passMediaURL,
  setBlankFields,
  showPopupAction,
  setFormEdit,
})(CreateInstructionsSteps);
